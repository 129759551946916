const triggerCopyScannerLink = (event) => {
  // const data = {
  //   publicIds: JSON.parse($("#scan-details").attr("data-public-ids")),
  //   rapid: $("#scan-details").attr("data-rapid"),
  // };
  // const sref = `sref=${encodeURIComponent(btoa(JSON.stringify(data)))}`;
  // const copyURL = `${$("#scan-details").attr("data-scan-domain")}?${sref}`;
  
  const copyURL = window.location.href;
  navigator.clipboard.writeText(copyURL);
  notify_success("Link copied to clipboard");
};

const triggerOpenScannerLink = (event) => {
  const data = {
    publicIds: JSON.parse($("#scan-details").attr("data-public-ids")),
    rapid: $("#scan-details").attr("data-rapid"),
    spaceId: $("#scan-details").attr("data-space-id"),
  };
  const sref = `sref=${encodeURIComponent(btoa(JSON.stringify(data)))}`;

  const openURL = `${$("#scan-details").attr("data-scan-domain")}?${sref}`;

  var win = window.open(openURL, "_blank");
  win.focus();
};

const toggleRapidDataAttribute = (event) => {
  if ($(event.target).is(":checked")) {
    $("#scan-details").attr("data-rapid", "1");
  } else {
    $("#scan-details").attr("data-rapid", "0");
  }
};

const initScannerActions = () => {
  // Parse sref from URL and set data attributes for publicIds and rapid
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("sref")) {
    const data = JSON.parse(atob(decodeURIComponent(urlParams.get("sref"))));

    $("#scan-details").attr("data-public-ids", JSON.stringify(data.publicIds));
    $("#scan-details").attr("data-rapid", data.rapid);
  }

  // Attach event handlers
  $(".svp-body").on("click", ".js-copy-scanner-link", triggerCopyScannerLink);
  $(".svp-body").on("click", ".js-open-scanner-link", triggerOpenScannerLink);
  $(".svp-body").on("change", ".js-scanner-rapid-cb", toggleRapidDataAttribute);
};

export { initScannerActions };
