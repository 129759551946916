const initPhotoUpload = () => {
  function updatePhotoUploader(event) {
    const photoUploader = $(event.target).parents(".js-photo-uploader");
    const logoFile = photoUploader.find("#logo-file");
    const logoFileName = photoUploader.find("#logo-file-name");
    const logoPlaceholder = photoUploader.find("#logo-placeholder");

    // Update file name
    logoFileName.html(
      jQuery
        .trim(logoFile.val().replace(/C:\\fakepath\\/i, ""))
        .substring(0, 50)
        .trim(this)
    );

    // Update photo preview
    if (logoFile[0].files && logoFile[0].files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        logoPlaceholder.attr("src", e.target.result);
      };
      reader.readAsDataURL(logoFile[0].files[0]);
      logoPlaceholder.show();
    }

    // Click save if auto-upload is enabled
    if (photoUploader.data("auto-upload")) {
      notify_success("Uploading...");
      photoUploader.find("#js-photo-save").click();
      photoUploader.find("#logo-placeholder-filter").show();
    }
  }

  function triggerUploadWindow(event) {
    event.preventDefault();
    $("#logo-file").click();
  }

  $(".svp-body").on("change", ".js-photo-upload", updatePhotoUploader);
  $(".svp-body").on(
    "click",
    ".js-photo-auto-upload-trigger",
    triggerUploadWindow
  );
};

export default initPhotoUpload;
