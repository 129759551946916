const initFormWizard = () => {
  function wizardTransition(event) {

    const eventWizard = $(event.target).parents(".js-form-wizard");

    const nextStep = $(event.target).data("target-step");
    const currentStep = nextStep - 1;

    const nextBarStep = eventWizard.find(`[data-step="${nextStep}"]`);
    const currentBarStep = eventWizard.find(`[data-step="${currentStep}"]`);

    const nextSection = eventWizard.find(`[data-section="${nextStep}"]`);
    const currentSection = eventWizard.find(`[data-section="${currentStep}"]`);


    if (fieldsValidated(currentStep)) {
      nextBarStep.addClass("active");
      currentBarStep.removeClass("active");
      currentBarStep.addClass("completed");
      currentBarStep.find("span").html("✔");

      currentSection.slideToggle('slow')
      nextSection.slideToggle("slow");
    }

  }

  function wizardTransitionBack(event) {
    const eventWizard = $(event.target).parents(".js-form-wizard");

    const prevStep = $(event.target).data("target-step");
    const currentStep = prevStep + 1;

    const prevBarStep = eventWizard.find(`[data-step="${prevStep}"]`);
    const currentBarStep = eventWizard.find(`[data-step="${currentStep}"]`);

    const prevSection = eventWizard.find(`[data-section="${prevStep}"]`);
    const currentSection = eventWizard.find(`[data-section="${currentStep}"]`);

    prevBarStep.addClass("active");
    currentBarStep.removeClass("active");
    prevBarStep.removeClass("completed");
    prevBarStep.find("span").html(prevStep);

    currentSection.slideToggle("slow");
    prevSection.slideToggle("slow");
  }

  function fieldsValidated(step) {
    let errors = 0;

    if (step === 1) {
      if ($("#event_name").val() == "") {
        ++errors;
        notify_danger("Event name is required");
        setTimeout(function () {
          $("#event_name").addClass("blink-1 is-danger");
        }, 50);
      }

      const ssdatetime = Date.parse($("#singlestartdate").val() + " " + $("#singlestarttime").val());
      const sedatetime = Date.parse($("#singleenddate").val() + " " + $("#singleendtime").val());

      if (ssdatetime >= sedatetime) {
        ++errors;
        notify_danger("Start date and time must be before end date and time");
        setTimeout(function () {
          $("#singlestartdate").next().addClass("blink-1 is-danger");
          $("#singlestarttime").addClass("blink-1 is-danger");
          $("#singleenddate").next().addClass("blink-1 is-danger");
          $("#singleendtime").addClass("blink-1 is-danger");
        }, 50);
      }

      if (sedatetime < Date.now()) {
        ++errors;
        notify_danger("End date and time must be in the future");
        setTimeout(function () {
          $("#singleenddate").next().addClass("blink-1 is-danger");
          $("#singleendtime").addClass("blink-1 is-danger");
        }, 50);
      }
    } else if (step === 2) {
      if ($(".online-wrapper").is(":visible") && $("#event_online_host").val() == null) {
        ++errors;
        notify_danger("Online Platform is required");
        setTimeout(function () {
          $("#event_online_host").parent().addClass("blink-1 is-danger");
        }, 50);
      }

      if ($(".location-wrapper").is(":visible") && $("#new_location").length > 0) {
        if($("#location_name").val() == "") {
          ++errors;
          notify_danger("Location name is required");
          setTimeout(function () {
            $("#location_name").addClass("blink-1 is-danger");
          }, 50);
        }

        if($("#location_address").val() == "") {
          ++errors;
          notify_danger("Location address is required");
          setTimeout(function () {
            $("#location_address").addClass("blink-1 is-danger");
          } , 50);
        }
      }
    }

    if (errors > 0) {
      return false;
    } else {
      return true;
    }
  }

  $(".svp-body").on("click", ".js-form-wizard__trigger", wizardTransition);
  $(".svp-body").on("click", ".js-form-wizard__trigger--back", wizardTransitionBack);
};

export default initFormWizard;
