const initLocationToggler = () => {
  const toggleLocationWrapper = () => {
    $(".location-wrapper").toggle();
    $("#online_event").prop("checked", $(".online-wrapper").toggle().is(":visible"));
  };

  $(".svp-body").on("click", ".location-toggler", toggleLocationWrapper);
};

export { initLocationToggler };
