import consumer from "./consumer"

import {newActivity} from "../helpers/portal_helpers";

document.addEventListener("turbolinks:load", () => {
  const channelContainer = document.querySelector(".portal-channel-container[data-portal-id]");

  if (channelContainer) {

    console.log("PortalChannel found 🎉");
    const id = channelContainer.dataset.portalId;

    console.log("PortalChannel id: ", id);
    consumer.subscriptions.create({ channel: "PortalChannel", id: id }, {
      connected() {},

      disconnected() {},

      received(data) {
        console.log("PortalChannel received data:", data);
        switch (data["method"]) {
          case "new_activity":
            newActivity(data);
            break;
        }
      },

    });
  };
});
