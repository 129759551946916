const initInlineSearch = () => {
  let debounceTimer;
  const debounceDuration = 250; // 300 milliseconds

  $(".svp-body").on("input", ".js-inline-search .search-input", function () {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      submitFormAjax($(this).closest('form')[0]);
    }, debounceDuration);
  });

  function submitFormAjax(form) {

    const svpBody = form.closest('.svp-body');
    const downloadButton = svpBody.querySelector('#bookings-download');
    const searchInputs = form.querySelectorAll('.search-input');
    
    replaceUrlParams(searchInputs);

    if (downloadButton) {
      console.log('disabling download button');
      downloadButton.classList.add('is-disabled');
    }

    document.querySelector('#loading-dots').classList.remove('is-hidden');

    $.ajax({
      url: form.getAttribute('action'),
      type: "POST",
      data: new FormData(form),
      processData: false,
      contentType: false,
      success: function(response) {
        // Handle success
      },
      error: function (response) {
        console.error(response);
      },
    });
  }


  // fetch search query params and add in query=@query
  function replaceUrlParams(inputs) {

    var url = new URL(window.location.href);
    var params = new URLSearchParams(url.search);

    inputs.forEach((input) => {
      if (input.value === "") {
        params.delete(input.name);
      } else {
        params.set(input.name, input.value);
      }
    });

    params.delete('page'); // Always reset page

    url.search = params.toString();
    var new_url = url.toString();
    history.pushState(null, null, new_url);
    
  }
};

export { initInlineSearch };
